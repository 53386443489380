// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application.scss"

// TODO: we need to start migrating everything over to the webpacker approach,
// the JS location for that sits within app/javascript location. This will include migrating packages to the package.json
// Guide: https://www.fastruby.io/blog/rails/webpack/from-sprockets-to-webpacker.html
// More about Webpacker: https://prathamesh.tech/2019/08/26/understanding-webpacker-in-rails-6/

Rails.start()
// TODO: only enable Turbolinks once all JS under app/assets/javascript is moved over to Webpacker under app/javascript
// Turbolinks.start()
ActiveStorage.start()



